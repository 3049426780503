import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PrivateDetail from "@/views/PrivateDetail"

const routes = [
  {
    path: '/',
    name: 'home',
    component: PrivateDetail
  },
  {
    path: '/calculate',
    name: 'calculate',
    component: PrivateDetail
  },
  {
    path: '/videoPlayer',
    name: 'videoPlayer',
    component: PrivateDetail
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
